import { Button, ButtonProps, Loader, PolymorphicComponentProps } from '@mantine/core'
import { useCounter } from '@mantine/hooks'
import { useRouter } from 'next/router'
import { event as gaEvent } from 'nextjs-google-analytics/dist/interactions/event'
import React, { useEffect, useState } from 'react'

import getMonitization, { TrackedAdService } from '@/ads.config'
import { useAds } from '@/provider'
import { useClientDb } from '@/provider/useClientDB'

interface AdButtonSettings {
    id: string
    url?: string
    newTab?: boolean
    fullNavigate?: boolean
    adopenLimit?: number
    clickFn?: () => void
}

export type ButtonWithAdsProps = PolymorphicComponentProps<'a', ButtonProps> & AdButtonSettings

const ButtonWithAds = (props: ButtonWithAdsProps) => {
    const { isEnable } = useAds()
    const openLimit = props.adopenLimit || 1
    const { push } = useRouter()
    const { dbLoaded } = useClientDb()
    const [monetization, setMonetization] = useState<TrackedAdService | undefined>(undefined)
    const [count, handlers] = useCounter(openLimit)
    const [loading, setLoading] = useState<boolean>(false)

    let additionalProps: PolymorphicComponentProps<'a', ButtonProps> = {}

    if (loading) {
        additionalProps = {
            disabled: true,
            leftSection: <Loader size='xs' />,
        }
    }

    useEffect(() => {
        if (dbLoaded) {
            getMonitization().then((monetization) => {
                setMonetization(monetization)
            })
        }
    }, [dbLoaded])

    if (!monetization || count <= 0 || !isEnable) {
        if (props.clickFn) {
            return (
                <Button {...props} {...additionalProps} onClick={props.clickFn}>
                    {props.children}
                </Button>
            )
        } else {
            if (props.newTab) additionalProps.target = '_blank'
            return (
                <Button {...props} {...additionalProps} href={props.url} component='a'>
                    {props.children}
                </Button>
            )
        }
    } else {
        const storeAdOpen = async () => {
            gaEvent('click_ads', {
                category: 'ads',
                label: monetization.name,
                element: props.id,
            })
            await window.db.adsTracking.add({
                campaignId: monetization.name,
                elementId: props.id,
                eventStart: new Date(),
                eventEnd: new Date(),
            })
        }

        if (count === 1 && !props.newTab) {
            const navigateFn = async () => {
                setLoading(true)
                await storeAdOpen()
                window.open(monetization.targetUrl, '_blank')

                setTimeout(() => {
                    setLoading(false)
                    if (props.clickFn) {
                        props.clickFn()
                    } else if (props.fullNavigate) {
                        window.open(props.url!, '_self', 'noopener noreferrer')
                    } else {
                        push(props.url!)
                    }
                    handlers.decrement
                }, 300)
            }
            return (
                <Button {...props} disabled={loading} {...additionalProps} onClick={navigateFn}>
                    {props.children}
                </Button>
            )
        } else {
            const onClick = async () => {
                await storeAdOpen()
                window.open(monetization.targetUrl, '_blank')
                handlers.decrement()
            }
            return (
                <Button {...props} disabled={loading} {...additionalProps} onClick={onClick}>
                    {props.children}
                </Button>
            )
        }
    }
}

export default ButtonWithAds
