import { Grid, rgba } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'

interface AdFrameProps {
    isFixedHeight?: boolean
    overrideMobileBreakpoint?: boolean
}

export default function AdFrame({
    children,
    isFixedHeight,
    overrideMobileBreakpoint,
}: React.PropsWithChildren<AdFrameProps>) {
    const isBreakpointLargerThanMd = useMediaQuery(`(max-width: 1050px)`)

    const AdContainer = ({ children }: React.PropsWithChildren) => (
        <Grid.Col
            span={isBreakpointLargerThanMd ? 10 : overrideMobileBreakpoint ? 10 : 3}
            sx={(theme, u) => ({
                backgroundColor: process.env.NEXT_PUBLIC_ENV_DEV_MODE
                    ? rgba(theme.colors.gray[6], 0.3)
                    : '',
                // height: overrideMobileBreakpoint ? config.ad_height : '',
                [u.smallerThan('md')]: {
                    // height: !adsContext.isEnable ? '100px' : config.ad_height,
                    height: '10px',
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                // columns
            })}
        >
            {children}
        </Grid.Col>
    )

    return (
        <Grid grow>
            <AdContainer>
                {/*Left /Top Ad (adsterra)*/}

                {/*{adsContext.isEnable && (*/}
                {/*    <iframe*/}
                {/*        src='//www.profitablecreativeformat.com/watchnew?key=89473f8457c428976e40e7b5f3df178d'*/}
                {/*        width='300'*/}
                {/*        height='250'*/}
                {/*        frameBorder='0'*/}
                {/*        scrolling='no'*/}
                {/*    ></iframe>*/}
                {/*)}*/}

                {/*<script*/}
                {/*    async*/}
                {/*    src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${config.google_page_ads_client}`}*/}
                {/*    crossOrigin='anonymous'*/}
                {/*></script>*/}

                {/*<ins*/}
                {/*    className='adsbygoogle'*/}
                {/*    style={{ display: 'block' }}*/}
                {/*    data-ad-client={config.google_page_ads_client}*/}
                {/*    data-ad-slot='3591641866'*/}
                {/*    data-ad-format='auto'*/}
                {/*    data-full-width-responsive='true'*/}
                {/*></ins>*/}
                {/*<script*/}
                {/*    dangerouslySetInnerHTML={{*/}
                {/*        __html: `(adsbygoogle = window.adsbygoogle || []).push({});`,*/}
                {/*    }}*/}
                {/*/>*/}
            </AdContainer>
            <Grid.Col
                sx={(theme, u) => ({
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    [u.largerThan('md')]: {
                        minHeight: isFixedHeight && !overrideMobileBreakpoint ? '55vh' : '',
                    },
                })}
                span={4}
                p='20px'
            >
                {children}
            </Grid.Col>
            <AdContainer>
                {/*Right /Bottom Ad (adsterra)*/}
                {/*{adsContext.isEnable && (*/}
                {/*    <iframe*/}
                {/*        src='//www.profitablecreativeformat.com/watchnew?key=89473f8457c428976e40e7b5f3df178d'*/}
                {/*        width='300'*/}
                {/*        height='250'*/}
                {/*        frameBorder='0'*/}
                {/*        scrolling='no'*/}
                {/*    ></iframe>*/}
                {/*)}*/}

                {/*<Script*/}
                {/*    async*/}
                {/*    data-cfasync='false'*/}
                {/*    id='ad-left-top'*/}
                {/*    src='https://pl20254970.highcpmrevenuegate.com/70f01b521c7430e028f386893748aaad/invoke.js'*/}
                {/*/>*/}
                {/*<div id='container-70f01b521c7430e028f386893748aaad'></div>*/}

                {/*<script*/}
                {/*    async*/}
                {/*    src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${config.google_page_ads_client}`}*/}
                {/*    crossOrigin='anonymous'*/}
                {/*></script>*/}

                {/*<ins*/}
                {/*    className='adsbygoogle'*/}
                {/*    style={{ display: 'block' }}*/}
                {/*    data-ad-client='ca-pub-3979880217016057'*/}
                {/*    data-ad-slot='4095076888'*/}
                {/*    data-ad-format='auto'*/}
                {/*    data-full-width-responsive='true'*/}
                {/*></ins>*/}
                {/*<script*/}
                {/*    dangerouslySetInnerHTML={{*/}
                {/*        __html: `(adsbygoogle = window.adsbygoogle || []).push({});`,*/}
                {/*    }}*/}
                {/*/>*/}
            </AdContainer>
        </Grid>
    )
}
