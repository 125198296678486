export function increaseCountKey(key: string) {
    const currentCount = Number(localStorage.getItem(key) || 0)
    localStorage.setItem(key, String(currentCount + 1))
}
export const getCountKey = (key: string) => Number(localStorage.getItem(key) || 0)

export const setInfiniteBool = (key: string, value: boolean) => {
    if (value) localStorage.setItem(key, 'true')
    else localStorage.removeItem(key)
}

export const getInfiniteBool = (key: string) => !!localStorage.getItem(key)
