import { Space } from '@mantine/core'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import React from 'react'

import AdFrame from '@/components/AdFrame'
import AppFramework from '@/components/AppFramework'
import DescriptionText from '@/components/DescriptionText'
import Feature from '@/components/Feature'
import LinkComponent from '@/components/LinkComponent'
import { fetchList } from '@/features/api/query'
import { APIList } from '@/features/api/types'

export default function Home({ preloadData }: InferGetStaticPropsType<typeof getStaticProps>) {
    return (
        <AppFramework>
            <AdFrame isFixedHeight>
                <LinkComponent preloadData={preloadData} />
            </AdFrame>
            <Space h='md' />
            <Feature preloadData={preloadData.map((list) => list.name)} />
            <DescriptionText />
        </AppFramework>
    )
}

export const getStaticProps: GetStaticProps<{
    preloadData: APIList[]
}> = async () => {
    const preloadData = await fetchList('https://api2.bypass.city')
    return { props: { preloadData } }
}
