import { Card, Container, rem, SimpleGrid, Text, useMantineTheme } from '@mantine/core'
import { IconActivityHeartbeat, IconDiscountCheck } from '@tabler/icons-react'
import React from 'react'

import FeatureCardSupportedSites from '@/components/FeatureCardSupportedSites'
import config from '@/config'
import { ApiSupportedSitesResponse } from '@/features/api/types'

import classes from './Feature.module.css'

function CustomCard({
    title,
    Icon,
    children,
}: {
    title: string
    children: React.ReactNode
    Icon: JSX.Element
}) {
    return (
        <Card key={title} shadow='md' radius='md' className={classes.card} padding='xl'>
            {Icon}
            <Text fz='lg' fw={500} className={classes.cardTitle} mt='md'>
                {title}
            </Text>
            {children}
        </Card>
    )
}

function Feature({
    preloadData,
}: React.PropsWithChildren<{ preloadData?: ApiSupportedSitesResponse[] }>) {
    const theme = useMantineTheme()

    return (
        <Container size='lg' py='xl'>
            <SimpleGrid cols={{ base: 1, md: 3 }} spacing='xl' mt={50}>
                <FeatureCardSupportedSites preloadData={preloadData} />
                <CustomCard
                    title='Instant Response'
                    Icon={
                        <IconActivityHeartbeat
                            style={{ width: rem(50), height: rem(50) }}
                            stroke={2}
                            color={theme.colors.blue[6]}
                        />
                    }
                >
                    <Text fz='sm' c='dimmed' mt='sm'>
                        {config.website_url_name} is a fast and responsive service that will get you the
                        link you need in no time!
                        <b> The bypass is instant and the link is ready to be used.</b>
                    </Text>
                </CustomCard>
                <CustomCard
                    title='Quick and Easy'
                    Icon={
                        <IconDiscountCheck
                            style={{ width: rem(50), height: rem(50) }}
                            stroke={2}
                            color={theme.colors.blue[6]}
                        />
                    }
                >
                    <Text fz='sm' c='dimmed' mt='sm'>
                        {config.website_url_name} is a simple and easy to use service that will bypass
                        supported link shorteners in no time!
                    </Text>
                </CustomCard>
            </SimpleGrid>
        </Container>
    )
}

export default Feature
