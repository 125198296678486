import { rem } from '@mantine/core'
import { createStyles } from '@mantine/emotion'
import { useInterval } from '@mantine/hooks'
import { animated, AnimatedProps, useTransition } from '@react-spring/web'
import React, { CSSProperties, useEffect, useState } from 'react'

import { ApiSupportedSitesResponse } from '@/features/api/types'

const useStyles = createStyles((theme, _, u) => ({
    title: {
        textAlign: 'center',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 800,
        fontSize: rem(40),
        letterSpacing: -1,
        [u.dark]: {
            color: theme.white,
        },
        [u.light]: {
            color: theme.black,
        },

        marginBottom: theme.spacing.xs,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        [u.smallerThan('xs')]: {
            fontSize: rem(28),
        },
    },
    titleSite: {
        color: theme.colors.blue[5],
        textDecorationStyle: 'wavy',
        textDecorationColor: theme.colors.teal[5],
        textDecorationThickness: '2px',
        textDecorationLine: 'underline',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing.xl,
    },
}))

export const SupportedSitesSlider = ({
    preloadData,
}: React.PropsWithChildren<{
    preloadData: ApiSupportedSitesResponse[]
}>) => {
    const [index, set] = useState(0)
    const onClick = () => set((state) => (state + 1) % preloadData.length)
    const interval = useInterval(() => onClick(), 2000)
    const [loaded, setLoaded] = useState<boolean>(false)
    const { classes } = useStyles()

    useEffect(() => {
        interval.start()
        setLoaded(true)
        return () => interval.stop()
    }, [interval])

    const pages: ((
        props: AnimatedProps<{
            style: CSSProperties
        }>
        // eslint-disable-next-line react/display-name
    ) => React.ReactElement)[] = preloadData.map((page) => ({ style }) => (
        <animated.div key={page} className={classes.title} style={style}>
            <p>
                Bypass <span className={classes.titleSite}>{page}</span>
            </p>
        </animated.div>
    ))

    const transitions = useTransition(index, {
        from: { opacity: 0, transform: 'translateX(-20%)' },
        enter: { opacity: 1, transform: 'translateX(0%)' },
        leave: { opacity: 0, transform: 'translateX(20%)' },
    })

    if (!loaded) {
        return (
            <div className={classes.titleContainer}>
                <div className={classes.title}>
                    <p>
                        Bypass <span className={classes.titleSite}>Work.ink</span>
                    </p>
                </div>
            </div>
        )
    }

    return (
        <div className={classes.titleContainer}>
            {transitions((style, i) => {
                const Page = pages[i]
                return <Page style={style} />
            })}
        </div>
    )
}

export default SupportedSitesSlider
