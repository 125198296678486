import { DateTime, Duration } from 'luxon'

import { storageKeys } from '@/components/stats/sorageKeys'
import config from '@/config'
import { getCountKey } from '@/utils/track/trackEevent'

enum AdServices {
    AdMaven = 'AdMaven',
    FusionAds = 'fusionAds',
    CostumeAds = 'CostumeAds',
}

interface DurationLimit {
    duration: Duration
    limit: number
}

export abstract class TrackedAdService {
    abstract name: AdServices
    abstract enabled: boolean
    abstract requirement(): Promise<boolean>
    abstract minWaitTime: Duration
    abstract targetUrl: string

    constructor() {}
}

class FusionAds extends TrackedAdService {
    name = AdServices.FusionAds
    enabled = true
    minWaitTime = Duration.fromObject({ seconds: 10 })
    targetUrl = config.adDirectLink
    async requirement() {
        return (
            (
                await window.db.adsTracking
                    .where('campaignId')
                    .equals(this.name)
                    .and((ad) => ad.eventStart > DateTime.now().minus({ minutes: 3 }).toJSDate())
                    .toArray()
            ).length <= 4
        )
    }
}

class CostumeAds extends TrackedAdService {
    name = AdServices.CostumeAds
    enabled = true
    minWaitTime = Duration.fromObject({ seconds: 10 })
    targetUrl = 'https://b.adbypass.org'
    async requirement() {
        return (
            (
                await window.db.adsTracking
                    .where('campaignId')
                    .equals(this.name)
                    .and((ad) => ad.eventStart > DateTime.now().minus({ minutes: 30 }).toJSDate())
                    .toArray()
            ).length <= 1 && getCountKey(storageKeys.resolveToMega) > 2
        )
    }
}

const adServices: TrackedAdService[] = [new FusionAds(), new CostumeAds()]

const getMonitization = async (): Promise<TrackedAdService | undefined> => {
    for (const service of adServices) {
        if (service.enabled && (await service.requirement())) {
            return service
        }
    }
    return undefined
}

export default getMonitization
