import { Accordion, Button, Container, Flex, Text, Title } from '@mantine/core'
import { IconHeart } from '@tabler/icons-react'
import Link from 'next/link'
import React from 'react'

import config from '@/config'

const DescriptionText = () => {
    return (
        <Container mt='lg' mb='lg'>
            <Text ta='center'>
                <Title mb='xl'>Frequently Asked Questions</Title>
            </Text>

            <Accordion defaultValue='supported' variant='contained'>
                <Accordion.Item value='supported'>
                    <Accordion.Control>What Ad-Link sites are supported ?</Accordion.Control>
                    <Accordion.Panel>
                        <Text>We support many different Sites</Text>
                        <Text fs='italic'>Including, linkvertise</Text>
                        <Text>
                            ... but also many others, like: Ad-Maven, adf.ly, AdFoc.us, AdShrink.it,
                            boost.ink, BoostFusedGT, leasurepartment.xyz, LetsBoost MediaBooster,
                            Rekonise, shorte.st/sh.st, Social Unlocks, Sub2Get, Sub2Unlock.com,
                            Sub2Unlock.net and many more. Every ad-link site under the sun is probably
                            supported and can be bypassed.
                        </Text>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='ip-restrictions'>
                    <Accordion.Control>Are bypasses restricted ?</Accordion.Control>
                    <Accordion.Panel>
                        <Text>No they are not!</Text>
                        <Text>
                            We do not restrict any bypasses. You can bypass as many links as you want
                            without any restrictions. We do not have any IP restrictions or any other
                            restrictions. You can bypass as many links as you want.
                        </Text>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='how-does-it-work'>
                    <Accordion.Control>How does it work ?</Accordion.Control>
                    <Accordion.Panel>
                        Bypass linkvertise in just a few clicks. Insert your link and enjoy the result.
                        Our process makes it extremely easy to reach your destination quickly. No endless
                        and anoying steps to get to your destination. Just one click and you are done.
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value='support-pastes'>
                    <Accordion.Control>Do we support Pastes ?</Accordion.Control>
                    <Accordion.Panel>
                        <b>Yes we do.</b> For linkvertise or other sites we will show you the pastes
                        content after you bypassed the link. No extra steps needed. We also extract
                        direct paste data from some common paste sites like pastebin.com, rentry.co,
                        pastelink.com, justpaste.it ... the list goes on. Over 30 in total !
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value='errors'>
                    <Accordion.Control>What if I get an error ?</Accordion.Control>
                    <Accordion.Panel>
                        <b>Don{"'"}t worry.</b> If you get an error, just try again. If the error
                        persists, check if you entered the correct link.
                        <br />
                        Other reasons may be that the site you are trying to bypass is not supported, the
                        ad-link site you are trying to bypass is down or the link got removed.
                        <br />
                        <br />
                        If you have problems with bypassing a linkvertise, you also can try{' '}
                        <Link href='/how-to-install-userscript'>our userscript.</Link>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value='ads'>
                    <Accordion.Control>Do we show ads ?</Accordion.Control>
                    <Accordion.Panel>
                        We show ads to help us pay for server costs and other expenses. We disable all
                        ads once we reach our monthly funding goal. You can help us reach our goal by
                        donating to the project.
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item value='donate'>
                    <Accordion.Control>How can I help ?</Accordion.Control>
                    <Accordion.Panel>
                        <b>There are many ways to help us.</b> You can help us by donating to the
                        project, disabling your adblocker, bookmarking our page so you find us again or
                        sharing our site with your friends.
                        <Flex mt='xl' justify='flex-end' align='center' direction='row'>
                            <Button
                                variant='outline'
                                color='red'
                                component='a'
                                href={config.donate_link}
                                leftSection={<IconHeart size='1rem' stroke={1.5} />}
                            >
                                Donate
                            </Button>
                        </Flex>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}

export default DescriptionText
